import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import styles from '../styles/homestyle.css';
import Sidebar from './Sidebar';

const InformationOfficielle = () => {
  const [formData, setFormData] = useState({
    rccm: '',
    organisme: '',
    banque: '',
    licenceExportation: '',
    licenceImportation: '',
    commentaire: '',
    identification: '',
    documents: '',
    societyName: '',
    bclBg: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
    console.log(formData);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Important pour validation de votre compte.
    </Tooltip>
  );

  return (
    <main>
      <div className="container-fluid">
          <div className="row">
                <div className="col-md-2">
                   <Sidebar/>
                </div>

                <div className="col-md-10">
                    <section className="content-form card" style={{ maxWidth: 'none' }}>
                        <h5 className="text-center">Les informations officielles de votre entreprise</h5>
                        <form id="annonceForm" onSubmit={handleSubmit}>
                            <fieldset>
                                <legend style={{ fontWeight: 'bold', color: 'red' }}>Veuillez remplir les champs suivants</legend>
                                <hr />
                                <br />
                                <div className="row ">
                                    <div className="form-group col-6">
                                    <label htmlFor="rccm">Numéro du registre de commerce: <span style={{ color: 'red' }}>*</span></label>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={renderTooltip}
                                    >
                                        <input 
                                        type="text" 
                                        id="rccm" 
                                        name="rccm" 
                                        placeholder="N° Registre de commerce" 
                                        value={formData.rccm} 
                                        onChange={handleChange}
                                        className="form-control" 
                                        required 
                                        />
                                    </OverlayTrigger>
                                    </div>
                                    
                                    <div className="form-group col-6">
                                    <label htmlFor="organisme">Délivré par: <span style={{ color: 'red' }}>*</span></label>
                                    <input 
                                        type="text" 
                                        id="organisme" 
                                        name="organisme" 
                                        placeholder="Organisme + ville + pays" 
                                        value={formData.organisme} 
                                        onChange={handleChange}
                                        className="form-control" 
                                        required 
                                    />
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="form-group col-6">
                                    <label htmlFor="banque">Quel est le nom de votre banque principale (facultatif):</label>
                                    <input 
                                        type="text" 
                                        id="banque" 
                                        name="banque" 
                                        placeholder="Banque" 
                                        value={formData.banque} 
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                    </div>
                                    
                                    <div className="form-group col-6">
                                    <label htmlFor="licenceExportation">Quel est votre numéro de licence d'exportation: <span style={{ color: 'red' }}>*</span></label>
                                    <input 
                                        type="text" 
                                        id="licenceExportation" 
                                        name="licenceExportation" 
                                        placeholder="Numéro de licence d'exportation" 
                                        value={formData.licenceExportation} 
                                        onChange={handleChange}
                                        className="form-control" 
                                        required 
                                    />
                                    </div>
                                    
                                    <div className="form-group col-12">
                                    <label htmlFor="licenceImportation">Quel est votre numéro de licence d'importation: <span style={{ color: 'red' }}>*</span></label>
                                    <input 
                                        type="text" 
                                        id="licenceImportation" 
                                        name="licenceImportation" 
                                        placeholder="Numéro de licence d'importation" 
                                        value={formData.licenceImportation} 
                                        onChange={handleChange}
                                        className="form-control" 
                                        required 
                                    />
                                    </div>
                                </div>
                                <hr />
                                <div className="form-row">
                                    <div className="form-group col-12">
                                    <label htmlFor="commentaire">Vous pouvez nous adresser un commentaire sur la situation de votre entreprise pour mieux expliquer votre situation officielle d'entrepreneur: (Facultatif)</label>
                                    <textarea 
                                        id="commentaire" 
                                        name="commentaire" 
                                        className="form-control" 
                                        rows="4" 
                                        value={formData.commentaire} 
                                        onChange={handleChange} 
                                        placeholder="Parlez-nous brièvement de votre entreprise"
                                    />
                                    </div>
                                </div>
                            </fieldset>
                            
                            <fieldset>
                            <legend style={{ fontWeight: 'bold', color: 'red' }}>Veuillez répondre aux questions suivantes</legend>
                            
                            <div className="form-group">
                                <span>Vous disposez d'un numéro d'identification d'entreprise sur registre de commerce ou sur un registre national:</span>
                                <div className="radio-group">
                                <input 
                                    type="radio" 
                                    id="identification-oui" 
                                    name="identification" 
                                    value="oui" 
                                    checked={formData.identification === 'oui'}
                                    onChange={handleChange} 
                                    required 
                                />
                                <label htmlFor="identification-oui">Oui</label>
                                <input 
                                    type="radio" 
                                    id="identification-non" 
                                    name="identification" 
                                    value="non" 
                                    checked={formData.identification === 'non'}
                                    onChange={handleChange} 
                                    required 
                                />
                                <label htmlFor="identification-non">Non</label>
                                </div>
                            </div>
                            
                            <div className="form-group">
                                <span>Sur simple demande, vous acceptez d'envoyer une copie des documents officiels d'enregistrement et d'identification:</span>
                                <div className="radio-group">
                                <input 
                                    type="radio" 
                                    id="documents-oui" 
                                    name="documents" 
                                    value="oui"
                                    checked={formData.documents === 'oui'}
                                    onChange={handleChange} 
                                />
                                <label htmlFor="documents-oui">Oui</label>
                                <input 
                                    type="radio" 
                                    id="documents-non" 
                                    name="documents" 
                                    value="non"
                                    checked={formData.documents === 'non'}
                                    onChange={handleChange} 
                                />
                                <label htmlFor="documents-non">Non</label>
                                </div>
                            </div>
                            
                            <div className="form-group">
                                <span>Sur simple demande, accepteriez-vous de fournir le nom de plusieurs sociétés avec lesquelles vous travaillez déjà ? <span style={{ color: 'red' }}>*</span></span>
                                <div className="radio-group">
                                <input 
                                    type="radio" 
                                    id="society_name-oui" 
                                    name="societyName" 
                                    value="oui" 
                                    checked={formData.societyName === 'oui'}
                                    onChange={handleChange} 
                                    required 
                                />
                                <label htmlFor="society_name-oui">Oui</label>
                                <input 
                                    type="radio" 
                                    id="society_name-non" 
                                    name="societyName" 
                                    value="non" 
                                    checked={formData.societyName === 'non'}
                                    onChange={handleChange} 
                                    required 
                                />
                                <label htmlFor="society_name-non">Non</label>
                                </div>
                            </div>

                            <div className="form-group">
                                <span>Pour des transactions d'achat supérieures à 50 000 €, êtes-vous en mesure de fournir une BCL ou une BG au vendeur?</span>
                                <div className="radio-group">
                                <input 
                                    type="radio" 
                                    id="bcl_bg-oui" 
                                    name="bclBg" 
                                    value="oui"
                                    checked={formData.bclBg === 'oui'}
                                    onChange={handleChange} 
                                />
                                <label htmlFor="bcl_bg-oui">Oui</label>
                                <input 
                                    type="radio" 
                                    id="bcl_bg-non" 
                                    name="bclBg" 
                                    value="non"
                                    checked={formData.bclBg === 'non'}
                                    onChange={handleChange} 
                                />
                                <label htmlFor="bcl_bg-non">Non</label>
                                </div>
                                <div className="alert alert-info">
                                <span>Une BCL est une lettre d'engagement de la banque émise pour le compte d'un client confirmant sa capacité financière pour une transaction d'achat. Une BG est une garantie bancaire.</span>
                                </div>
                            </div>
                            </fieldset>
                            
                            <div className="form-group">
                            <button type="submit" className="btn btn-primary btn-large" style={{ width: '100%' }}>Enregistrer</button>
                            </div>
                        </form>
                    </section>
                </div>
          </div>
        
       
      </div>
    </main>
  );
};

export default InformationOfficielle;
