import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 

import '../../styles/auth.css';

function Register() {
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [flashMessage, setFlashMessage] = useState('');
  const [flashMessageType, setFlashMessageType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const countryCode = response.data.country_code.toLowerCase();
        setCountryCode(countryCode);
      } catch (error) {
        console.error('Erreur lors de la récupération du pays de l\'utilisateur:', error);
      }
    };

    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error('Erreur lors de la récupération de l\'adresse IP:', error);
      }
    };

    fetchCountry();
    fetchIpAddress();
  }, []);

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setPasswordError('Les mots de passe doivent être les mêmes.');
      return;
    }

    if (!validateEmail(email)) {
      setEmailError('L\'adresse email n\'est pas valide.');
      return;
    }

    setPasswordError('');
    setEmailError('');
    setIsLoading(true);

    try {
      const response = await axios.post('http://localhost:5000/api/auth/register', {
        fullname,
        email,
        phone,
        password,
        confirmPassword,
        country: countryCode,
        ipAddress
      });
      setFlashMessage('Merci pour votre inscription ! Un lien de confirmation vous a été envoyé. Veuillez vérifier votre boîte email pour confirmation.');
      setFlashMessageType('success');
      setFullname('');
      setEmail('');
      setPhone('');
      setPassword('');
      setConfirmPassword('');
      setTimeout(() => {
        navigate('/login');
      }, 60000);
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement:', error);
      setFlashMessage('Erreur lors de l\'inscription, veuillez réessayer.');
      setFlashMessageType('error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <main>
        <div className="container">
          <div className="col-lg-6 offset-lg-3">
            <div className="card">
              <h2>Créer un compte</h2>
              {flashMessage && (
                <div className={`alert ${flashMessageType === 'success' ? 'alert-success' : 'alert-danger'}`} role="alert">
                  {flashMessage}
                </div>
              )}
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nom et prénom"
                    value={fullname}
                    onChange={(e) => setFullname(e.target.value)}
                    required
                  />
                </div>
                  {emailError && <div className="text-danger"> <i className="fa fa-exclamation-triangle"></i> {emailError}</div>}
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    />
                </div>
                <div className="form-group">
                  <PhoneInput
                    country={countryCode}
                    value={phone}
                    onChange={handlePhoneChange}
                    containerClass="intl-tel-input"
                    inputClass="form-control"
                  />
                </div>
                <div className="form-group position-relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="form-control"
                    placeholder="Entrer un mot de passe"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span
                    className="password-toggle"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                {passwordError && <div className="text-danger"> <i className="fa fa-exclamation-triangle"></i> {passwordError}</div>}
                <div className="form-group position-relative">
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    className="form-control"
                    placeholder="Confirmer le mot de passe"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  <span
                    className="password-toggle"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                  >
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                <div className="form-group d-flex align-items-center">
                  <input type="checkbox" checked id="accept" className="form-check mr-2" required />
                  <label htmlFor="accept" style={{ fontSize: 14, marginLeft: '5px' }}>
                    J'accepte les <a href="https://www.google.com/policies/terms/" style={{ color: 'red' }}>Conditions générales d'utilisation de Espace Négoce</a>
                  </label>
                </div>
                <p style={{ fontSize: 12, color: 'red' }}>
                  Vous êtes engagé à faire preuve de transparence et de responsabilité dans l'utilisation de notre plateforme.
                  <strong> Votre adresse IP : {ipAddress} </strong>
                </p>
                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                  {isLoading ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span style={{ marginLeft: '5px' }}>En cours de création</span>
                    </>
                  ) : (
                    'Créer un compte'
                  )}
                </button>
              </form>

              <div className="divider"></div>
              <Link to="/login" style={{ textAlign: 'center', color: '#003366' }}>
                Vous avez déjà un compte ? Connectez-vous
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Register;
