
import React from 'react';
import { Link } from 'react-router-dom'; 
import PostAdModal from './PostAdModal';
import SearchBar from './SearchBar';
import ProfileMenu from './ProfileMenu';
import '../styles/homestyle.css';

function Navbar() {
  const [showModal, setShowModal] = React.useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <header>
      <div className="container">
        <div className="main-container">
          <div className="logo">
            <Link to="/" className="no-decoration" >
               <h1 className="logo-bold"><span className="logo-one">ESPACE</span> <span className="logo-two">NEGOCE</span></h1>
            </Link>
          </div>
          <SearchBar />
          <div className="header-right">
            <a className="post-ad-btn" onClick={handleShow}>
              <i className="fas fa-bullhorn"></i> Déposer une annonce
            </a>
            <ProfileMenu />
           
          </div>
        </div>
      </div>
      <PostAdModal show={showModal} handleClose={handleClose} />
    </header>
  );
}

export default Navbar;
