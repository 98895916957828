import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './Sidebar';

const Formation = () => {
  // Liste des vidéos avec URL, titre et description
  const videos = [
    {
        url: "https://www.youtube.com/embed/LJa1EiWBJVw",
        title: "Ce produit Juteux va nous rapporter des milliards de FCFA en Dropshipping des matières Premières",
        description: "Description de la vidéo 1. Cette vidéo traite de ..."
    },
    
    {
    url: "https://www.youtube.com/embed/8zLyrSCUZHo",
    title: "Le NEGOCE INTERNATIONAL DES PRODUITS AGRICOLES: Comment Bien Démarrer ce Business en Afrique",
    description: "Description de la vidéo 2. Cette vidéo traite de ..."
    },

    {
      url: "https://www.youtube.com/embed/0dg6o1E90PI",
      title: "Vidéo 1: Négoce des denrées alimentaires en Afrique 🤑 : voici comment trouver des clients à l'international",
      description: "Description de la vidéo 3. Cette vidéo traite de ..."
    },
    {
      url: "https://www.youtube.com/embed/IzErn7OE5iE",
      title: "Vidéo 2: 🚀 Devenez un Maître du Négoce de matière premières en 4 Étapes Essentielles ! 🌍",
      description: "Description de la vidéo 4. Cette vidéo montre ..."
    },
    {
      url: "https://www.youtube.com/embed/SdAny28jRVQ",
      title: "Vidéo 3: Découvrez le business du négoce en Afrique 💼 : Fonctionnement, opportunités et stratégies ! 💰",
      description: "Description de la vidéo 5. Regardez cette vidéo pour ..."
    },
    {
      url: "https://www.youtube.com/embed/ME77tLJsGQI",
      title: "Vidéo 4: Transformation par le négoce : Témoignages inspirants et opportunités lucratives! 🌟💼",
      description: "Description de la vidéo 6. Apprenez comment ..."
    }
  ];

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <Sidebar />
          </div>

          <div className="col-md-10">
            <div className="row">
              {videos.map((video, index) => (
                <div className="col-md-6 mb-4" key={index}>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      src={video.url}
                      allowFullScreen
                      title={video.title}
                      style={{ height: '350px', width: '100%' }} // Ajuste la hauteur des vidéos
                    ></iframe>
                  </div>
                  <div className="video-details mt-2">
                    <h5>{video.title}</h5>
                    <p>{video.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Formation;
