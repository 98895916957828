import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/homestyle.css'; 
import '../styles/Messagerie.css'; 
import Sidebar from './Sidebar';

const Messagerie = () => {
  const [activeChat, setActiveChat] = useState('chat1');
  const [userName, setUserName] = useState('Nicaise AYIMANDE');
  const [messageText, setMessageText] = useState('');
  const [chats, setChats] = useState({
    chat1: [
      { text: 'Hi there!', type: 'received', timestamp: '2024-07-27 15:00' },
      { text: 'Hello!', type: 'sent', timestamp: '2024-07-27 15:02' }
    ],

    chat2: [
      { text: "Hey, what's up?", type: 'received' },
      { text: 'Not much, you?', type: 'sent' }
    ]
  });

  const handleChatClick = (chatId, name) => {
    setActiveChat(chatId);
    setUserName(name);
  };

  const handleMessageSend = () => {
    if (messageText.trim() !== '') {
      setChats(prevChats => ({
        ...prevChats,
        [activeChat]: [
          ...prevChats[activeChat],
          { text: messageText, type: 'sent', timestamp: new Date().toLocaleString() }
        ]
      }));
      setMessageText('');
      const activeChatElement = document.getElementById(activeChat);
      if (activeChatElement) {
        activeChatElement.scrollTop = activeChatElement.scrollHeight;
      }
    }
  };

  const handleTextAreaChange = (e) => {
    setMessageText(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleMessageSend();
    }
  };

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
              <Sidebar />
          </div>

          <div className="col-md-10">
            <div className="row">
              <div className="col-md-4 card" style={{margin: "0px auto"}}>
                <div className="text-center" style={{ color: '#003366', marginTop: '10px' }}>
                  <h2 style={{fontSize: "1.5rem;"}}>Messagerie</h2>
                </div>
                <ul className="list-group list-group-flush chat-list">
                  <li className={`list-group-item ${activeChat === 'chat1' ? 'active' : ''} chat-item`} data-chat="chat1" onClick={() => handleChatClick('chat1', 'Nicaise AYIMANDE')}>
                    <div className="d-flex align-items-center">
                      <img src="https://via.placeholder.com/40" alt="User" className="rounded-circle mr-2" />
                      <div className="notification-indicator"></div>
                      <div className="pushleft">
                        <h5 className="mb-0">Nicaise AYIMANDE</h5>
                        <small className="text-muted">Last message...</small>
                      </div>
                    </div>
                  </li>
                  <li className={`list-group-item ${activeChat === 'chat2' ? 'active' : ''} chat-item`} data-chat="chat2" onClick={() => handleChatClick('chat2', 'Darius HOUESSOU-KODE')}>
                    <div className="d-flex align-items-center">
                      <img src="https://via.placeholder.com/40" alt="User" className="rounded-circle mr-2" />
                      <div className="notification-indicator"></div>
                      <div className="pushleft">
                        <h5 className="mb-0">Darius HOUESSOU-KODE</h5>
                        <small className="text-muted">Last message...</small>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="col-md-8 ">
                <div className="  d-flex align-items-center p-3 border-bottom chat-header">
                  <img src="https://via.placeholder.com/40" alt="User" className="rounded-circle mr-2" />
                  <h5 className="mb-0" id="chatUserName" className="pushleft">{userName}</h5>
                </div>
                <div className="p-3 chat-messages" id="chatMessages">
                  {Object.keys(chats).map(chatId => (
                    <div
                      key={chatId}
                      className={`chat-content  ${activeChat === chatId ? '' : 'd-none'}`}
                      id={chatId}
                    >
                      {chats[chatId].map((message, index) => (
                        <div className="d-flex flex-row mb-2" key={index}>
                          <div className={`message ${message.type} p-2 rounded`}>
                            <p className="mb-0">{message.text}</p>
                            {message.timestamp && <small className="text-muted">{message.timestamp}</small>}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <div className="chat-user-container">
                  <textarea
                    placeholder="Votre message ici..."
                    className="user-post-textarea"
                    value={messageText}
                    onChange={handleTextAreaChange}
                    onKeyDown={handleKeyDown}
                  />
                  <div className="chat-user-actions">
                    <button className="submit-post-btn" onClick={handleMessageSend}>
                      <i className="fa fa-paper-plane"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Messagerie;
