import React, { useState } from 'react';
import { Form, Row, Col, Button, Card, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../styles/UserProfile.module.css';

const UserProfile = () => {
  const [logo, setLogo] = useState(null);
  const [hasWebsite, setHasWebsite] = useState('non'); // État pour suivre la sélection du site web


  const handleLogoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setLogo(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleWebsiteChange = (e) => {
    setHasWebsite(e.target.value);
  };

  return (
    <main >
      <div className="container">
        <Row>
          {/* Form Section */}
          <Col md={6}>
              <section className="card" style={{ maxWidth: 'none' }} >
                  <h3 style={{ fontWeight: 'bold', color: 'red' }}> Mettre à jour votre fiche société</h3>
                <Form id="annonceForm">
                  <Form.Group>
                    <Form.Label >
                      
                    </Form.Label>
                  </Form.Group>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Nom et prénom<span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control type="text" id="fullname" placeholder="Nom et prénom" required />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Email<span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control type="email" placeholder="Email" required />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Téléphone<span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control type="text" id="phone" placeholder="Téléphone" required />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Adresse<span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control type="text" placeholder="Adresse" required />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Code Postal<span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control type="text" id="codezip" placeholder="Code postal" required />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Ville<span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control type="text" id="city" placeholder="Ville" required />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group>
                    <Form.Label>Pays<span style={{ color: 'red' }}>*</span></Form.Label>
                    <Form.Control as="select" required>
                      <option value="">Sélectionnez un pays</option>
                      <option value="afghanistan">Afghanistan</option>
                      <option value="south-africa">Afrique du Sud</option>
                      <option value="angola">Angola</option>
                      <option value="zimbabwe">Zimbabwe</option>
                    </Form.Control>
                  </Form.Group>
                  <Row className="mt-2 mb-2">
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Numéro d'immatriculation (RCS ou SIREN)<span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control type="text" placeholder="RCS ou Numéro SIREN" required />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>N° de TVA intracommunautaire (si vous êtes en Europe)</Form.Label>
                        <Form.Control type="text" placeholder="N° de TVA" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Vous êtes<span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control as="select" required>
                          <option value="">Sélectionner</option>
                          <option value="fournisseur">Producteur</option>
                          <option value="client">Fabricant</option>
                          <option value="partenaire">Grossiste</option>
                          <option value="conseiller">Courtier</option>
                          <option value="conseiller">Détaillant</option>
                          <option value="conseiller">Groupement</option>
                          <option value="conseiller">Association</option>
                          <option value="conseiller">Autres</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>En<span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control as="select" required>
                          <option value="">Rien de précis</option>
                          <option value="1">Lait et Produits Laitiers</option>
                          <option value="3">Fruits et Légumes</option>
                          <option value="17">Fruits Secs et Graines Grillées</option>
                          <option value="47">Énergie Renouvelable</option>
                          <option value="autres">Autres</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mt-2 mb-2">
                      <Form.Group >
                        <Form.Label>Effectifs<span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control as="select" required>
                          <option value="">Sélectionner</option>
                          <option value="fournisseur">1 à 5 personnes</option>
                          <option value="10-10">5 à 10 personnes</option>
                          <option value="10-20">10 à 20 personnes</option>
                          <option value="20-40">20 à 40 personnes</option>
                          <option value="40-60">40 à 60 personnes</option>
                          <option value="60-100">60 à 100 personnes</option>
                          <option value="+100">+100 personnes</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>Avez-vous un site internet ?<span style={{ color: 'red' }}>*</span></Form.Label>
                        
                        <div className="radio-group mt-3 mb-3">
                        
                        <Form.Check
                          type="radio"
                          id="website-oui"
                          name="website"
                          value="oui"
                          label="Oui"
                          checked={hasWebsite === 'oui'}
                          onChange={handleWebsiteChange}
                          required
                        />
                        <Form.Check
                          type="radio"
                          id="website-non"
                          name="website"
                          value="non"
                          label="Non"
                          checked={hasWebsite === 'non'}
                          onChange={handleWebsiteChange}
                          required
                        />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  {hasWebsite === 'oui' && (
                    <Form.Group>
                      <Form.Label> Alors, Veuillez renseigner l'adresse de votre site web.</Form.Label>
                      <Form.Control type="text" placeholder="Adresse de votre site web http://" />
                    </Form.Group>
                  )}
                  <Form.Group>
                    <Form.Label>Précisions sur votre activité<span style={{ color: 'red' }}>*</span></Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={6}
                      placeholder="Profitez de cet espace pour bien décrire votre entreprise, votre métier, vos marchés, etc. Ces informations seront diffusées à vos interlocuteurs avant toute prise de contact."
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mt-2 mb-4">
                    <Form.Label>Indications sur vos capacités<span style={{ color: 'red' }}>*</span></Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={6}
                      placeholder="Profitez de cet espace pour préciser si vous le souhaitez votre chiffre d'affaires, vos garanties ou toute indication qui pourra garantir le sérieux de votre entreprise."
                      required
                    />
                  </Form.Group>
                  <Button type="submit" variant="primary" style={{ width: '100%' }}>Enregistrer</Button>
                </Form>
              </section>
          </Col>

         
          <Col md={6}>
            <div className="card"  style={{ maxWidth: 'none' }}>
              <h4>Informations requises</h4>
              
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-left">
                  Mettez à jour le formulaire et enregistrez-le.
                </li>
                <li className="list-group-item d-flex  align-items-left">
                  Nous prenons en compte votre demande et mettons à jour automatiquement votre profil.
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-left">
                  Mettez à jour le formulaire et enregistrez-le.
                </li>
               
              </ul>
              <Alert variant="warning" className="d-flex align-items-left mt-2">
                <i className="fas fa-exclamation-triangle fa-2x mr-2"></i>
                Ces indications nous servent à mieux connaître votre entreprise et ainsi mieux vous présenter sur le site. Retenez aussi que les profils correctement remplis sont mis en valeur par notre plateforme.
              </Alert>
            </div>

            <div className="card mt-4 p-3"  style={{ maxWidth: 'none' }}>
              <h4>Votre logo</h4>
              <Form.Group>
                <Form.Label>Pour modifier votre logo, sélectionnez l'image du logo puis cliquez sur envoyer (format png, jpg ou gif inférieur à 100 ko, il sera affiché avec une largeur de 100 pixels)</Form.Label>
                <div className="logo-preview " style={{  marginTop: '65px', textAlign: 'center', display: 'block' }}>
                  {logo && <img src={logo} alt="Logo Preview" style={{ width: '100px' }} />}
                </div>
                <Form.Control className="mt-4"
                  type="file"
                  id="logo"
                  name="logo"
                  accept="image/*"
                  onChange={handleLogoChange}
                />
              </Form.Group>
              <Button type="submit" variant="primary" style={{ width: '100%' }}>Enregistrer</Button>
            </div>
          </Col>
        </Row>
      </div>
    </main>
  );
}

export default UserProfile;
