import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import '../../styles/auth.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // État pour afficher/masquer le mot de passe
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      const response = await axios.post('http://localhost:5000/api/auth/login', {
        email,
        password
      });

      const { token } = response.data;

      localStorage.setItem('authToken', token);
      navigate('/');
    } catch (error) {
      if (error.response && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Email ou mot de passe incorrect.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main>
      <div className="container">
        <div className="col-lg-6 offset-lg-3">
          <div className="card p-4">
            <h2>Se connecter</h2>
            {errorMessage && (
              <div className="alert alert-danger">
                <i className="fa fa-exclamation-circle"></i>  {errorMessage}
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group position-relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control"
                  placeholder="Mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span
                  className="password-toggle"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              <button type="submit" className="btn btn-primary" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span style={{ marginLeft: '5px' }}>Connexion en cours...</span>
                  </>
                ) : (
                  'Se connecter'
                )}
              </button>
            </form>
            <Link to="/forgot-password" className="d-block mt-3 mb-3">Mot de passe oublié ?</Link>
            <hr />
            <Link to="/register" className="btn btn-primary mt-3">Créer un compte</Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
