import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Sidebar.css';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <aside className="sidebar d-none d-sm-block" style={{ textAlign: 'left' }}>
      <nav>
        <ul style={{ paddingLeft: '0px' }}>
          <li>
            <Link to="/contact-user">
              <i className="fas fa-user-friends"></i> Mes contacts
            </Link>
          </li>
          <li>
            <Link to="/networking">
              <i className="fas fa-users"></i> Réseautage
            </Link>
          </li>
          <li>
            <Link to="/formations">
              <i className="fas fa-video"></i> Formations
            </Link>
          </li>
          <li>
            <Link to="/annonces">
              <i className="fas fa-bullhorn"></i> Mes annonces
            </Link>
          </li>
          <li>
            <Link to="/pages">
              <i className="fas fa-flag"></i> Pages
            </Link>
          </li>
          <li>
            <Link to="/evenements">
              <i className="fas fa-calendar-alt"></i> Événements
            </Link>
          </li>
          <li>
            <Link to="/actualites">
              <i className="fas fa-rss"></i> Fils d'actualité
            </Link>
          </li>
          <li>
            <Link to="/historique">
              <i className="fas fa-history"></i> Historique
            </Link>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
