import React, { useRef, useState } from 'react';
import { Modal, Button, Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

const renderTooltip = (message) => (
  <Tooltip>{message}</Tooltip>
);

const FormInputWithTooltip = ({ id, label, placeholder, tooltip, ...props }) => (
  <Form.Group className="mb-3">
    <OverlayTrigger placement="top" overlay={renderTooltip(tooltip)}>
      <Form.Label htmlFor={id}>{label}</Form.Label>
    </OverlayTrigger>
    <Form.Control id={id} placeholder={placeholder} {...props} />
  </Form.Group>
);

const FileInput = ({ fileInputRef, updateFileName }) => (
  <Form.Group className="mb-3">
    <div className="mb-3">
      <Form.Control
        type="file"
        name="image"
        id="file"
        className="inputfile"
        accept="image/*"
        multiple
        ref={fileInputRef}
        onChange={(e) => updateFileName(e.target)}
      />
      <Form.Label htmlFor="file" id="file-label">
        Cliquer pour sélectionner une ou plusieurs images de votre annonce
      </Form.Label>
    </div>
    <div className="col-lg-12 preview-container" id="preview-container">
      {/* Prévisualisation des images */}
    </div>
  </Form.Group>
);

function PostAdModal({ show, handleClose }) {
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function updateFileName(input) {
    const label = document.getElementById('file-label');
    const previewContainer = document.getElementById('preview-container');
    previewContainer.innerHTML = ''; // Clear previous previews

    if (input.files.length > 0) {
      if (input.files.length > 1) {
        label.textContent = `${input.files.length} images sélectionnées`;
      } else {
        label.textContent = input.files[0].name;
      }

      Array.from(input.files).forEach((file, index) => {
        const reader = new FileReader();
        reader.onload = function(e) {
          const previewItem = document.createElement('div');
          previewItem.classList.add('preview-item');

          const img = document.createElement('img');
          img.src = e.target.result;

          const info = document.createElement('div');
          info.classList.add('info');
          info.innerHTML = `${(file.size / 1024).toFixed(2)} KB`;

          const removeBtn = document.createElement('button');
          removeBtn.classList.add('remove-btn');
          removeBtn.innerHTML = '&times;';
          removeBtn.onclick = function() {
            previewItem.remove();
            const dt = new DataTransfer();
            const files = input.files;

            for (let i = 0; i < files.length; i++) {
              if (i !== index) {
                dt.items.add(files[i]);
              }
            }
            input.files = dt.files;

            if (input.files.length > 1) {
              label.textContent = `${input.files.length} images sélectionnées`;
            } else if (input.files.length === 1) {
              label.textContent = input.files[0].name;
            } else {
              label.textContent = 'Cliquer pour sélectionner une ou plusieurs images';
            }
          };

          previewItem.appendChild(img);
          previewItem.appendChild(info);
          previewItem.appendChild(removeBtn);
          previewContainer.appendChild(previewItem);
        };
        reader.readAsDataURL(file);
      });
    } else {
      label.textContent = 'Cliquer pour sélectionner une ou plusieurs images';
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('typeBesoin', document.getElementById('typeBesoin').value);
    formData.append('domaineAgro', document.getElementById('domaineAgro').value);
    formData.append('titreAnnonce', document.getElementById('titreAnnonce').value);
    formData.append('descriptionAnnonce', document.getElementById('descriptionAnnonce').value);

    if (document.getElementById('quantite').value) {
      formData.append('quantite', document.getElementById('quantite').value);
    }
    if (document.getElementById('tarifs').value) {
      formData.append('tarifs', document.getElementById('tarifs').value);
    }
    if (document.getElementById('marque').value) {
      formData.append('marque', document.getElementById('marque').value);
    }
    if (document.getElementById('restrictions').value) {
      formData.append('restrictions', document.getElementById('restrictions').value);
    }
    if (document.getElementById('conditions').value) {
      formData.append('conditions', document.getElementById('conditions').value);
    }
    if (document.getElementById('precisions').value) {
      formData.append('precisions', document.getElementById('precisions').value);
    }

    const files = fileInputRef.current.files;
    for (let i = 0; i < files.length; i++) {
      formData.append('images', files[i]);
    }

    try {
      const response = await fetch('/api/annonces', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to submit ad');
      }

      // Traitez la réponse de manière appropriée
      const result = await response.json();
      console.log('Annonce soumise avec succès:', result);
      handleClose(); // Fermez la modal après le succès
    } catch (error) {
      setError(error.message);
      console.error('Erreur lors de la soumission:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Déposer une annonce</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form id="annonceForm" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <fieldset className="custom-fieldset">
                <legend style={{ fontWeight: 'bold', color: 'red' }}>Ces champs sont obligatoires</legend>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Type de besoin *</Form.Label>
                      <Form.Select id="typeBesoin" required>
                        <option value="">Sélectionner</option>
                        <option value="fournisseur">Recherche de fournisseur</option>
                        <option value="client">Recherche de client</option>
                        <option value="partenaire">Recherche de partenaire</option>
                        <option value="conseiller">Recherche de conseiller</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Domaine d'agroalimentaire *</Form.Label>
                      <Form.Select id="domaineAgro">
                        <option value="">Sélectionner</option>
                        <option value="1">Lait et Produits Laitiers</option>
                        <option value="3">Fruits et Légumes</option>
                        <option value="17">Fruits Secs et Graines Grillées</option>
                        <option value="29">Jus Concentré Sirop</option>
                        <option value="28">Boisson Alcoolisée</option>
                        <option value="37">Vins</option>
                        <option value="7">Boissons Non Alcoolisée</option>
                        <option value="2">Poissons</option>
                        <option value="15">Viandes et Oeufs</option>
                        <option value="42">Coquillage Crustacé</option>
                        <option value="30">Animaux Vivants</option>
                        <option value="21">Café Cacao Thé</option>
                        <option value="34">Patisserie</option>
                        <option value="5">Produits Sucrés</option>
                        <option value="8">Épices Aromates</option>
                        <option value="6">Matières Grasses</option>
                        <option value="23">Huiles</option>
                        <option value="33">Champignon Levure</option>
                        <option value="12">Aliments Pour Animaux</option>
                        <option value="4">Féculents</option>
                        <option value="24">Céréales</option>
                        <option value="35">Conserve Semi Conserve</option>
                        <option value="25">Bois Charbon Fibre</option>
                        <option value="11">Hygiène Entretien</option>
                        <option value="22">Chimie Additif Engrais</option>
                        <option value="10">Matériels Machines</option>
                        <option value="27">Conditionnement</option>
                        <option value="13">Horticulture Plante Biocarburant</option>
                        <option value="48">Matière Végétale</option>
                        <option value="46">Semence Graine Plant</option>
                        <option value="16">Conseil et Formation</option>
                        <option value="19">Publicité-Nouveaux Produits</option>
                        <option value="41">Investissement Capitaux</option>
                        <option value="31">Industrie Exploitation</option>
                        <option value="32">Emploi Représentation</option>
                        <option value="38">Pétrole Gaz</option>
                        <option value="39">Pierre Roche</option>
                        <option value="40">Ciment</option>
                        <option value="26">Métaux</option>
                        <option value="43">Transport Transit Logistique</option>
                        <option value="44">Équipement Maritime</option>
                        <option value="45">Mine Terrain Concession</option>
                        <option value="47">Énergie Renouvelable</option>
                        <option value="14">Autres</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label>Titre de l'annonce *</Form.Label>
                  <Form.Control
                    type="text"
                    id="titreAnnonce"
                    placeholder="Donner un titre à votre annonce"
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Description *</Form.Label>
                  <Form.Control
                    as="textarea"
                    id="descriptionAnnonce"
                    rows={6}
                    required
                    placeholder="Description complète la plus détaillée possible(100 caractères minimum). Profitez de cet espace pour bien décrire votre annonce. Pensez par exemple à préciser les quantités, les atouts, l'intérêt pour votre interlocuteur, etc ...  Plus votre annonce sera détaillée et précise, plus vous aurez de chance de trouver rapidement un bon contact."
                  />
                </Form.Group>
              </fieldset>
            </Col>

            <Col md={6}>
              <fieldset className="custom-fieldset">
                <legend style={{ fontWeight: 'bold', color: '#000' }}>Ces champs sont optionnels</legend>
                <FileInput fileInputRef={fileInputRef} updateFileName={updateFileName} />
                <Row>
                  <Col md={6}>
                    <FormInputWithTooltip
                      id="quantite"
                      label="Quantité"
                      placeholder="Exemple: 10 tonnes"
                      tooltip="Si vous avez une quantité, indiquez la quantité concernée ci-dessus."
                    />
                  </Col>
                  <Col md={6}>
                    <FormInputWithTooltip
                      id="tarifs"
                      label="Tarifs"
                      placeholder="Exemple: 1000 € la tonne"
                      tooltip="Si vous avez un tarif, indiquez le tarif concerné ci-dessus."
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormInputWithTooltip
                      id="marque"
                      label="Marque"
                      placeholder="Exemple: Coca-Cola"
                      tooltip="Si votre annonce concerne une marque, indiquez la marque concernée ci-dessus."
                    />
                  </Col>
                  <Col md={6}>
                    <FormInputWithTooltip
                      id="restrictions"
                      label="Restrictions diverses"
                      placeholder="Restrictions diverses ?"
                      tooltip="Indiquez les restrictions, par exemple : limite géographique d'exportation, date limite de vente..."
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormInputWithTooltip
                      id="conditions"
                      label="Conditions et expédition"
                      placeholder="Conditions et expédition ?"
                      tooltip="Indiquez les conditions d'expédition concernées."
                    />
                  </Col>
                  <Col md={6}>
                    <FormInputWithTooltip
                      id="precisions"
                      label="Précisions sur la transaction"
                      placeholder="Précisions sur la transaction ?"
                      tooltip="LOI - FCO, etc. Utilisez les abréviations."
                    />
                  </Col>
                </Row>
              </fieldset>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Fermer</Button>
        <Button variant="primary" type="submit" form="annonceForm" disabled={loading}>
          {loading ? 'Publication...' : 'Publier cette annonce'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PostAdModal;
