import React, { useRef, useEffect } from 'react';

const PostArea = ({ postTextareaRef, autoResizeTextarea }) => {
  useEffect(() => {
    const postTextarea = postTextareaRef.current;

    if (postTextarea) {
      postTextarea.addEventListener('input', () => autoResizeTextarea(postTextarea));
    }

    return () => {
      if (postTextarea) {
        postTextarea.removeEventListener('input', () => autoResizeTextarea(postTextarea));
      }
    };
  }, [postTextareaRef, autoResizeTextarea]);

  return (
    <div className="post-area">
      <div className="post-user-container">
        <img src="https://via.placeholder.com/40" alt="User" className="user-avatar" />
        <textarea
          placeholder="Hello, Nicaise, de quoi as-tu besoin aujourd'hui ?"
          data-toggle="tooltip"
          title="Publie ton besoin dès maintenant et reçois des propositions de la communauté."
          className="user-post-textarea"
          ref={postTextareaRef}
        ></textarea>
        <div className="post-user-actions">
          <button className="submit-post-btn">
            <i className="fa fa-paper-plane"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostArea;
