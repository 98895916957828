import React, { useRef, useState } from 'react';
import CommentForm from './CommentForm';
import Comment from './Comment';
import ImageGallery from './ImageGallery';
import PostActions from './PostActions'; 
import postsData from '../data/postData';

const Post = () => {
  const [likes, setLikes] = useState(0);
  const [liked, setLiked] = useState(false);
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [comments, setComments] = useState([]);
  const [replyingTo, setReplyingTo] = useState(null);

  const commentTextareaRef = useRef(null);
  const replyTextareaRefs = useRef([]);

  const handleLikeClick = () => {
    setLiked(!liked);
    setLikes(liked ? likes - 1 : likes + 1);
  };

  const handleCommentClick = () => {
    setShowCommentForm(!showCommentForm);
  };

  const handleCommentSubmit = () => {
    const newComment = {
      text: commentTextareaRef.current.value,
      replies: []
    };
    setComments([...comments, newComment]);
    commentTextareaRef.current.value = '';
  };

  const handleReplyClick = (index) => {
    setReplyingTo(replyingTo === index ? null : index);
  };

  const handleReplySubmit = (index) => {
    const updatedComments = [...comments];
    const replyText = replyTextareaRefs.current[index].value;
    updatedComments[index].replies.push(replyText);
    setComments(updatedComments);
    setReplyingTo(null);
  };

  const handleReplyChange = (index, value) => {
    replyTextareaRefs.current[index].value = value;
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleCommentSubmit();
    }
  };

  const autoResizeTextarea = (textarea) => {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  return (
    <div className="posts-list">
      {postsData.map(post => {
        const images = post.images && post.images.length > 0 ? post.images : [];

        return (
          <div key={post.id} className="post">
            <div className="post-header">
              <img className="avatar" src={post.avatar} alt="User" />
              <div className="post-header-left">
                <h3>{post.authorName}</h3>
                <p>{post.postTime}</p>
              </div>
              <div className="post-header-right">
                <h3>{post.country}</h3>
                <p>
                  <img
                    src={post.countryFlag}
                    className="country-flag mr-2"
                    height="50"
                    alt={`Drapeau du ${post.country}`}
                  />
                  {post.country}
                </p>
              </div>
            </div>
            <div className="post-content">
              <div className="col-lg-12 col-sm-12 col-xs-12">
                <p className="text-right"><strong>{post.title}</strong></p>
              </div>
              {images.length > 1 ? (
                <ImageGallery images={images} />
              ) : (
                <img src={images[0]} alt="Post" className="single-image" />
              )}
             <div className="row"> 
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <div className="d-flex justify-content-between">
                    <p className="text-muted"><i>{post.needType}</i></p>
                    <p className="text-muted"><i>Catégorie: {post.domain}</i></p>
                  </div>
                  <p>{post.description}</p>
                </div>
                <div className="col-lg-4 offset-lg-1 mt-3 mb-3 col-sm-4 offset-sm-2 col-xs-12">
                  <button className="btn btn-primary w-100">
                    <i className="fa fa-info-circle"></i> En savoir plus
                  </button>
                </div>

                <div className="col-lg-4 offset-lg-1  col-sm-4 offset-sm-2 mt-3 mb-3 col-xs-12">
                  <button className="btn btn-primary w-100">
                    <i className="fa fa-paper-plane"></i> Envoyer Message
                  </button>
                </div>
              </div>

                
               
            </div>

            {/* <div className="row">
              <div className="col-lg-12 col-sm-12 col-xs-12">
              </div>
            </div> */}



            {/* <PostActions
              likes={likes}
              liked={liked}
              handleLikeClick={handleLikeClick}
              comments={comments}
              handleCommentClick={handleCommentClick}
            />
            <CommentForm
              showCommentForm={showCommentForm}
              handleCommentSubmit={handleCommentSubmit}
              handleKeyPress={handleKeyPress}
              autoResizeTextarea={autoResizeTextarea}
              commentTextareaRef={commentTextareaRef}
            />
            <div className="comments">
              {comments.map((comment, index) => (
                <Comment
                  key={index}
                  comment={comment}
                  index={index}
                  handleReplyClick={handleReplyClick}
                  replyingTo={replyingTo}
                  handleReplySubmit={handleReplySubmit}
                  handleReplyChange={handleReplyChange}
                  replyTextareaRefs={replyTextareaRefs}
                />
              ))}
            </div> */}
          </div>
        );
      })}
    </div>
  );
};

export default Post;
