// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import InformationOfficielle from './components/InformationOfficielle';
import Networking  from './components/Networking';
import ContactUser from './components/ContactUser';
import AlertesMailUser from './components/AlertesMailUser';
import Messagerie from './components/Messagerie';
import HomePage from './components/HomePage';
import Formation from './components/Formation';

import './App.css';
import ForgotPassword from './components/auth/ForgotPassword';
import UserProfile from './components/UserProfile';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          
          <Route path="/" element={<HomePage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} /> 
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/user-information" element={<InformationOfficielle />} />
          <Route path="/networking" element={<Networking />} />
          <Route path="/contact-user" element={<ContactUser />} />
          <Route path="/alert-user" element={<AlertesMailUser />} />
          <Route path="/messenger" element={<Messagerie />} />
          <Route path="/formations" element={<Formation />} />


        </Routes>
      </div>
    </Router>
  );
}

export default App;
