// src/data/postsData.js

const postsData = [
  {
    id: 1,
    avatar: 'https://via.placeholder.com/50',
    authorName: 'Nicaise',
    postTime: 'Il y a 1 heure',
    country: 'Bénin',
    countryFlag: 'https://www.countryflags.com/wp-content/uploads/flag-jpg-xl-20-1536x1024.jpg',
    title: 'Recherche de Partenaires pour la Production de Lait',
    needType: 'A la recherche de partenaire',
    domain: 'Lait et Produits Laitiers',
    images: [
      'https://via.placeholder.com/500x300/FF0000/FFFFFF?text=Image+1', // Rouge
      'https://via.placeholder.com/500x300/00FF00/FFFFFF?text=Image+2', // Vert
      'https://via.placeholder.com/500x300/0000FF/FFFFFF?text=Image+3', // Bleu
      'https://via.placeholder.com/500x300/FFFF00/FFFFFF?text=Image+4'  // Jaune
    ],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam euismod sapien in orci volutpat, at fringilla purus laoreet.'
  },
  {
    id: 2,
    avatar: 'https://via.placeholder.com/50',
    authorName: 'Aline',
    postTime: 'Il y a 3 heures',
    country: 'France',
    countryFlag: 'https://www.countryflags.com/wp-content/uploads/flag-jpg-xl-20-1536x1024.jpg',
    title: 'Recherche de Clients pour des Fruits et Légumes Bio',
    needType: 'A la recherche de client',
    domain: 'Fruits et Légumes',
    images: [
      'https://via.placeholder.com/500x300/FF00FF/FFFFFF?text=Image+5', // Magenta
      'https://via.placeholder.com/500x300/00FFFF/FFFFFF?text=Image+6'  // Cyan
    ],
    description: 'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Duis auctor turpis ut sem aliquet.'
  },
  {
    id: 3,
    avatar: 'https://via.placeholder.com/50',
    authorName: 'Marc',
    postTime: 'Il y a 5 heures',
    country: 'Brésil',
    countryFlag: 'https://www.countryflags.com/wp-content/uploads/flag-jpg-xl-20-1536x1024.jpg',
    title: 'Partenaires Recherchés pour la Production de Jus',
    needType: 'A la recherche de partenaire',
    domain: 'Jus Concentré Sirop',
    images: [
      'https://via.placeholder.com/500x300/FFFF00/000000?text=Image+7'  // Jaune
    ],
    description: 'Curabitur sollicitudin erat at magna malesuada, nec luctus sapien aliquam. Nullam sit amet efficitur libero.'
  },
  {
    id: 4,
    avatar: 'https://via.placeholder.com/50',
    authorName: 'Sophie',
    postTime: 'Il y a 2 jours',
    country: 'États-Unis',
    countryFlag: 'https://www.countryflags.com/wp-content/uploads/flag-jpg-xl-20-1536x1024.jpg',
    title: 'Fournisseurs Recherchés pour des Épices Aromates',
    needType: 'A la recherche de fournisseur',
    domain: 'Épices Aromates',
    images: [
      'https://via.placeholder.com/500x300/FF4500/FFFFFF?text=Image+8', // Orange
      'https://via.placeholder.com/500x300/2E8B57/FFFFFF?text=Image+9', // Vert
      'https://via.placeholder.com/500x300/20B2AA/FFFFFF?text=Image+10'  // Turquoise
    ],
    description: 'Phasellus fermentum urna sed ligula sodales, sit amet efficitur elit gravida. Integer nec justo ut justo volutpat.'
  },
  {
    id: 5,
    avatar: 'https://via.placeholder.com/50',
    authorName: 'Jean',
    postTime: 'Il y a 1 jour',
    country: 'Canada',
    countryFlag: 'https://www.countryflags.com/wp-content/uploads/flag-jpg-xl-20-1536x1024.jpg',
    title: 'Recherche de Partenaires pour des Produits Sucrés',
    needType: 'A la recherche de partenaire',
    domain: 'Produits Sucrés',
    images: [
      'https://via.placeholder.com/500x300/8A2BE2/FFFFFF?text=Image+11', // Bleu violet
      'https://via.placeholder.com/500x300/A52A2A/FFFFFF?text=Image+12'  // Marron
    ],
    description: 'Suspendisse potenti. Sed consequat ligula ut est gravida, non venenatis arcu varius. Nam suscipit, ipsum ac bibendum tincidunt.'
  },
  {
    id: 6,
    avatar: 'https://via.placeholder.com/50',
    authorName: 'Marie',
    postTime: 'Il y a 4 heures',
    country: 'Espagne',
    countryFlag: 'https://www.countryflags.com/wp-content/uploads/flag-jpg-xl-20-1536x1024.jpg',
    title: 'Clients Recherchés pour des Boissons Non Alcoolisées',
    needType: 'A la recherche de client',
    domain: 'Boissons Non Alcoolisée',
    images: [
      'https://via.placeholder.com/500x300/DC143C/FFFFFF?text=Image+13', // Crimpson
      'https://via.placeholder.com/500x300/2F4F4F/FFFFFF?text=Image+14'  // Gris ardoise foncé
    ],
    description: 'Nulla facilisi. Morbi tincidunt nisi et urna placerat, sed ultrices nisi vehicula. Ut id felis eget ligula facilisis gravida.'
  },
  {
    id: 7,
    avatar: 'https://via.placeholder.com/50',
    authorName: 'Marie',
    postTime: 'Il y a 4 heures',
    country: 'Espagne',
    countryFlag: 'https://www.countryflags.com/wp-content/uploads/flag-jpg-xl-20-1536x1024.jpg',
    title: 'Clients Recherchés pour des Boissons Non Alcoolisées',
    needType: 'A la recherche de client',
    domain: 'Boissons Non Alcoolisée',
    images: [
      'https://via.placeholder.com/500x300/DC143C/FFFFFF?text=Image+13', // Crimpson
      'https://via.placeholder.com/500x300/2F4F4F/FFFFFF?text=Image+14'  // Gris ardoise foncé
    ],
    description: 'Nulla facilisi. Morbi tincidunt nisi et urna placerat, sed ultrices nisi vehicula. Ut id felis eget ligula facilisis gravida.'
  },

  {
    id: 8,
    avatar: 'https://via.placeholder.com/50',
    authorName: 'Marie',
    postTime: 'Il y a 4 heures',
    country: 'Espagne',
    countryFlag: 'https://www.countryflags.com/wp-content/uploads/flag-jpg-xl-20-1536x1024.jpg',
    title: 'Clients Recherchés pour des Boissons Non Alcoolisées',
    needType: 'A la recherche de client',
    domain: 'Boissons Non Alcoolisée',
    images: [
      'https://via.placeholder.com/500x300/DC143C/FFFFFF?text=Image+13', // Crimpson
      'https://via.placeholder.com/500x300/2F4F4F/FFFFFF?text=Image+14'  // Gris ardoise foncé
    ],
    description: 'Nulla facilisi. Morbi tincidunt nisi et urna placerat, sed ultrices nisi vehicula. Ut id felis eget ligula facilisis gravida.'
  },

  {
    id: 9,
    avatar: 'https://via.placeholder.com/50',
    authorName: 'Marie',
    postTime: 'Il y a 4 heures',
    country: 'Espagne',
    countryFlag: 'https://www.countryflags.com/wp-content/uploads/flag-jpg-xl-20-1536x1024.jpg',
    title: 'Clients Recherchés pour des Boissons Non Alcoolisées',
    needType: 'A la recherche de client',
    domain: 'Boissons Non Alcoolisée',
    images: [
      'https://via.placeholder.com/500x300/DC143C/FFFFFF?text=Image+13', // Crimpson
      'https://via.placeholder.com/500x300/2F4F4F/FFFFFF?text=Image+14'  // Gris ardoise foncé
    ],
    description: 'Nulla facilisi. Morbi tincidunt nisi et urna placerat, sed ultrices nisi vehicula. Ut id felis eget ligula facilisis gravida.'
  },
  {
    id: 10,
    avatar: 'https://via.placeholder.com/50',
    authorName: 'Marie',
    postTime: 'Il y a 4 heures',
    country: 'Espagne',
    countryFlag: 'https://www.countryflags.com/wp-content/uploads/flag-jpg-xl-20-1536x1024.jpg',
    title: 'Clients Recherchés pour des Boissons Non Alcoolisées',
    needType: 'A la recherche de client',
    domain: 'Boissons Non Alcoolisée',
    images: [
      'https://via.placeholder.com/500x300/DC143C/FFFFFF?text=Image+13', // Crimpson
      'https://via.placeholder.com/500x300/2F4F4F/FFFFFF?text=Image+14'  // Gris ardoise foncé
    ],
    description: 'Nulla facilisi. Morbi tincidunt nisi et urna placerat, sed ultrices nisi vehicula. Ut id felis eget ligula facilisis gravida.'
  }
];

export default postsData;
