import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';
// import '../styles/ContactUser.css'; 

const contacts = [
  { id: 1, name: 'Valérie Pale', domain: 'Technologie', location: 'Bénin', image: 'https://via.placeholder.com/80' },
  { id: 2, name: 'Valérie Pale', domain: 'Import/Export', location: 'Bénin', image: 'https://via.placeholder.com/80' },
  { id: 3, name: 'Valérie Pale', domain: 'Import/Export', location: 'France', image: 'https://via.placeholder.com/80' },
  { id: 4, name: 'Valérie Pale', domain: 'Technologie', location: 'France', image: 'https://via.placeholder.com/80' },
  { id: 5, name: 'Valérie Pale', domain: 'Producteur', location: 'USA', image: 'https://via.placeholder.com/80' },
  { id: 6, name: 'Valérie Pale', domain: 'Fournisseur', location: 'USA', image: 'https://via.placeholder.com/80' },
  { id: 7, name: 'Valérie Pale', domain: 'Courtier', location: 'Burkina Faso', image: 'https://via.placeholder.com/80' },
  { id: 8, name: 'Valérie Pale', domain: 'Producteur animalier', location: 'Burkina Faso', image: 'https://via.placeholder.com/80' }
];

const ContactUser = ({ title }) => {
  const printContacts = () => {
    window.print();
  };

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
              <Sidebar/>
          </div>

          <div className="col-md-7">
            <h2 className="my-4">Liste des membres qui ont pris vos coordonnées sur vos annonces.</h2>
            <hr />
            <div className="row">
              {contacts.length > 0 ? (
                contacts.map(contact => (
                  <div className="contact-card" key={contact.id}>
                    <img src={contact.image} alt={contact.name} />
                    <div className="name">{contact.name}</div>
                    <div className="title">Domaine d'activité: {contact.domain}</div>
                    <div className="common-relations">{contact.location}</div>
                    <Link to="/messenger">
                      <button className="connect-btn">
                        <i className="fa fa-paper-plane"></i> Message
                      </button>
                    </Link>
                  </div>
                ))
              ) : (
                <p>Aucun contact disponible.</p>
              )}
            </div>
          </div>

          <div className="col-md-3">
              <div className="alert alert-info" role="alert">
                  <h2 className="alert-heading">RAPPEL</h2>
                  <p>Voici la liste de vos contacts pour lesquels vous avez utilisé des unités. Cette liste vous est donnée à titre indicatif.</p>
                  <p>Afin d'éviter toute perte, nous vous conseillons de l'imprimer régulièrement.</p>
                  <p>Nous vous remercions pour votre compréhension.</p>
                  <button className="imprimession-btn" type="button" onClick={printContacts}>Imprimer mes contacts</button>
              </div>
          </div>

        </div>
      </div>
    </main>
  );
};

export default ContactUser;
