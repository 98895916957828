import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import '../styles/AlertesMailUser.css'; // Assurez-vous que le fichier CSS est présent

const AlertesMailUser = () => {
  return (
    <main>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="card p-4">
              <h2>Recevoir chaque matin les alertes par email !</h2>
              <p>Vous souhaitez être prévenu dès qu'un type d'affaire est diffusé ?</p>
              <p>Dans ce cas, il vous suffit d'indiquer ci-dessous les termes qui caractérisent ces affaires. Votre bulletin d'alerte sera envoyé chaque matin avant 9h.</p>
              <p><strong>Exemple:</strong> prune, chinchard, pistache, fromage brebis, ciment, escargot</p>
              <form>
                <div className="form-group">
                  <label htmlFor="alertTerms">Indiquez les termes :</label>
                  <input 
                    type="text" 
                    className="form-control" 
                    id="alertTerms" 
                    placeholder="Entrez les termes ici" 
                  />
                </div>
                <button 
                  type="submit" 
                  className="btn btn-primary w-100"
                >
                  Enregistrer
                </button>
              </form>
              <a href="#" className="btn btn-secondary mt-3 w-100">
                Ne plus recevoir les alertes...
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AlertesMailUser;
