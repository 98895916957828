// src/components/ProfileMenu.js
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'; 

function ProfileMenu() {
  return (
    <Dropdown className="profile-menu">
        <Dropdown className="dropdown">
            <Dropdown.Toggle id="dropdown-basic" variant="secondary" className="dropbtn my-custom-style">
                <img src="https://via.placeholder.com/40" alt="Avatar" className="avatar" /> Mon compte
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-content">
                <Dropdown.Item as={Link} to="/user-information">
                    <i className="fas fa-info-circle icon-blue"></i> Infos Officielles
                </Dropdown.Item>
                
                <Dropdown.Item as={Link} to="/user-profile">
                    <i className="fas fa-user-edit icon-blue"></i> Modifier votre profil
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/forgot-password">
                    <i className="fas fa-key icon-blue"></i> Mot de passe
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/alert-user">
                    <i className="fas fa-bell icon-blue"></i> Alertes/email
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item as={Link} to="/messenger">
                    <i className="fas fa-envelope icon-blue"></i> Messagerie
                </Dropdown.Item>

                {/* <Dropdown.Item as={Link} to="/factures">
                    <i className="fas fa-file-invoice icon-blue"></i> Mes factures
                </Dropdown.Item> */}

                <Dropdown.Item as={Link} to="/networking">
                    <i className="fas fa-address-book icon-blue"></i> Réseautage
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/contact-user">
                    <i className="fas fa-address-book icon-blue"></i> Contact
                </Dropdown.Item>
                {/* <Dropdown.Item as={Link} to="/ventes_achats">
                    <i className="fas fa-exchange-alt icon-blue"></i> Mes ventes & Achats
                </Dropdown.Item> */}
                <Dropdown.Item as={Link} to="/annonces">
                    <i className="fas fa-bullhorn icon-blue"></i> Mes annonces
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/temoigner">
                    <i className="fas fa-comment-dots icon-blue"></i> Témoigner
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item as={Link} to="/register">
                    <i className="fas fa-user-plus icon-blue"></i> S'inscrire
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/login">
                    <i className="fas fa-sign-in-alt icon-blue"></i> Se connecter
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/logout">
                    <i className="fas fa-sign-out-alt icon-blue"></i> Déconnexion
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </Dropdown>
  );
}

export default ProfileMenu;
