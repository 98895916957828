import React from 'react';
import '../../styles/auth.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const ForgotPassword = () => {
  return (
    <main>
      <div className="container">
        <div className="col-lg-6 offset-lg-3">
          <div className="card p-4">
            <h2>Mot de passe oublié ?</h2>
            <form action="#">
              <div className="form-group">
                <input 
                  type="email" 
                  className="form-control" 
                  placeholder="Entrer votre email" 
                  required 
                />
              </div>
              <button type="submit" className="btn btn-primary">Réinitialiser le mot de passe</button>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ForgotPassword;
