import React, { useState, useRef, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/homestyle.css';
import Sidebar from './Sidebar';
import PostArea from './PostArea';
import Post from './Post';

const HomePage = () => {
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [comments, setComments] = useState([]);
  const [likes, setLikes] = useState(0);
  const [liked, setLiked] = useState(false);
  const [replies, setReplies] = useState({});
  const [replyingTo, setReplyingTo] = useState(null);
  const postTextareaRef = useRef(null);
  const commentTextareaRef = useRef(null);
  const replyTextareaRefs = useRef({});

  const handleCommentClick = () => {
    setShowCommentForm(!showCommentForm);
  };

  const handleCommentSubmit = () => {
    if (commentTextareaRef.current) {
      const newComment = commentTextareaRef.current.value;
      if (newComment.trim()) {
        setComments([...comments, { text: newComment, replies: [] }]);
        commentTextareaRef.current.value = '';
        autoResizeTextarea(commentTextareaRef.current);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleCommentSubmit();
    }
  };

  const handleLikeClick = () => {
    if (liked) {
      setLikes(likes - 1);
    } else {
      setLikes(likes + 1);
    }
    setLiked(!liked);
  };

  const autoResizeTextarea = (textarea) => {
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  };

  const handleReplyClick = (index) => {
    setReplyingTo(index);
  };

  const handleReplySubmit = (index) => {
    if (replyTextareaRefs.current[index]) {
      const newReply = replyTextareaRefs.current[index].value;
      if (newReply?.trim()) {
        const newComments = [...comments];
        newComments[index].replies = [...(newComments[index].replies || []), newReply];
        setComments(newComments);
        replyTextareaRefs.current[index].value = '';
        setReplyingTo(null);
      }
    }
  };

  const handleReplyChange = (index, value) => {
    setReplies({ ...replies, [index]: value });
  };

  useEffect(() => {
    const postTextarea = postTextareaRef.current;
    const commentTextarea = commentTextareaRef.current;

    if (postTextarea) {
      postTextarea.addEventListener('input', () => autoResizeTextarea(postTextarea));
    }

    if (commentTextarea) {
      commentTextarea.addEventListener('input', () => autoResizeTextarea(commentTextarea));
      commentTextarea.addEventListener('keypress', handleKeyPress);
    }

    return () => {
      if (postTextarea) {
        postTextarea.removeEventListener('input', () => autoResizeTextarea(postTextarea));
      }
      if (commentTextarea) {
        commentTextarea.removeEventListener('input', () => autoResizeTextarea(commentTextarea));
        commentTextarea.removeEventListener('keypress', handleKeyPress);
      }
    };
  }, [showCommentForm, comments]);

  return (
    <div className="row" style={{ margin: '0 auto' }}>
      <div className="col-lg-2 d-none d-lg-block">
        <Sidebar />
      </div>
      <div className="col-lg-10 col-sm-12 col-xs-12">
        <div className="main-container" style={{ marginTop: '85px' }}>
          <section className="content">
            <PostArea postTextareaRef={postTextareaRef} autoResizeTextarea={autoResizeTextarea} />
            <Post
              post={{}} 
              likes={likes}
              liked={liked}
              handleLikeClick={handleLikeClick}
              handleCommentClick={handleCommentClick}
              showCommentForm={showCommentForm}
              comments={comments}
              handleCommentSubmit={handleCommentSubmit}
              handleReplyClick={handleReplyClick}
              replyingTo={replyingTo}
              handleReplySubmit={handleReplySubmit}
              handleReplyChange={handleReplyChange}
              handleKeyPress={handleKeyPress}
              autoResizeTextarea={autoResizeTextarea}
              commentTextareaRef={commentTextareaRef}
              replyTextareaRefs={replyTextareaRefs}
            />
          </section>
        </div>
      </div>
    </div>
  );
};

export default HomePage;