import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Networking.css'; 
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';

const Networking = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const buyCredits = () => {
    alert("Redirection vers la page d'achat de crédits.");
    closePopup();
  };

  // Exemple de données de contact
  const contacts = [
    { id: 1, fullname: 'Valérie Pale', domain: 'Agroalimentaire', location: 'Bénin', image: 'https://via.placeholder.com/80' },
    { id: 2, fullname: 'Mathieu Durant', domain: 'Agroalimentaire', location: 'France', image: 'https://via.placeholder.com/80' },
    { id: 3, fullname: 'Nathalie Tremblay', domain: 'Matières Premières', location: 'Canada', image: 'https://via.placeholder.com/80' },
    { id: 1, fullname: 'Valérie Pale', domain: 'Agroalimentaire', location: 'Bénin', image: 'https://via.placeholder.com/80' },
    { id: 2, fullname: 'Mathieu Durant', domain: 'Agroalimentaire', location: 'France', image: 'https://via.placeholder.com/80' },
    { id: 3, fullname: 'Nathalie Tremblay', domain: 'Matières Premières', location: 'Canada', image: 'https://via.placeholder.com/80' },
    { id: 1, fullname: 'Valérie Pale', domain: 'Agroalimentaire', location: 'Bénin', image: 'https://via.placeholder.com/80' },
    { id: 2, fullname: 'Mathieu Durant', domain: 'Agroalimentaire', location: 'France', image: 'https://via.placeholder.com/80' },
    { id: 3, fullname: 'Nathalie Tremblay', domain: 'Matières Premières', location: 'Canada', image: 'https://via.placeholder.com/80' },
    { id: 3, fullname: 'Nathalie Tremblay', domain: 'Matières Premières', location: 'Canada', image: 'https://via.placeholder.com/80' },


  ];

  const messagingContacts = [
    { id: 1, fullname: 'Valérie Pale', domain: 'Producteur', location: 'USA', image: 'https://via.placeholder.com/80' },
    { id: 2, fullname: 'Valérie Pale', domain: 'Fournisseur', location: 'USA', image: 'https://via.placeholder.com/80' },
    { id: 3, fullname: 'Valérie Pale', domain: 'Courtier', location: 'Burkina Faso', image: 'https://via.placeholder.com/80' },
    { id: 4, fullname: 'Valérie Pale', domain: 'Producteur animalier', location: 'Burkina Faso', image: 'https://via.placeholder.com/80' },

    { id: 1, fullname: 'Valérie Pale', domain: 'Producteur', location: 'USA', image: 'https://via.placeholder.com/80' },
    { id: 2, fullname: 'Valérie Pale', domain: 'Fournisseur', location: 'USA', image: 'https://via.placeholder.com/80' },
    { id: 3, fullname: 'Valérie Pale', domain: 'Courtier', location: 'Burkina Faso', image: 'https://via.placeholder.com/80' },
    { id: 4, fullname: 'Valérie Pale', domain: 'Producteur animalier', location: 'Burkina Faso', image: 'https://via.placeholder.com/80' },

    { id: 1, fullname: 'Valérie Pale', domain: 'Producteur', location: 'USA', image: 'https://via.placeholder.com/80' },
    { id: 2, fullname: 'Valérie Pale', domain: 'Fournisseur', location: 'USA', image: 'https://via.placeholder.com/80' },
    { id: 3, fullname: 'Valérie Pale', domain: 'Courtier', location: 'Burkina Faso', image: 'https://via.placeholder.com/80' },
    { id: 4, fullname: 'Valérie Pale', domain: 'Producteur animalier', location: 'Burkina Faso', image: 'https://via.placeholder.com/80' },
    { id: 3, fullname: 'Valérie Pale', domain: 'Courtier', location: 'Burkina Faso', image: 'https://via.placeholder.com/80' },
    { id: 4, fullname: 'Valérie Pale', domain: 'Producteur animalier', location: 'Burkina Faso', image: 'https://via.placeholder.com/80' },
    { id: 4, fullname: 'Valérie Pale', domain: 'Producteur animalier', location: 'Burkina Faso', image: 'https://via.placeholder.com/80' },

    // Ajoutez autant de contacts que nécessaire
  ];

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
              <Sidebar />
          </div>

          <div className="col-md-10">
            <h2 className="my-2">Bonne nouvelle 😀 Nicaise, ces membres sont dans le même secteur d'activité que vous!</h2>
            <hr />
            <div className="row">
              {contacts.map(contact => (
                <div className="contact-card" key={contact.id}>
                  <img src={contact.image} alt={contact.name} />
                  <div className="name">{contact.name}</div>
                  <div className="title">Domaine d'activité: {contact.domain}</div>
                  <div className="common-relations">{contact.location}</div>
                  <button className="connect-btn" onClick={openPopup}>
                    <i className="fa fa-paper-plane"></i> Initier un contact
                  </button>
                </div>
              ))}
            </div>

            <h2 className="my-4">Liste des contacts de votre messagerie</h2>
            <hr />
            <div className="row">
              {messagingContacts.map(contact => (
                <div className="contact-card" key={contact.id}>
                  <img src={contact.image} alt={contact.name} />
                  <div className="name">{contact.name}</div>
                  <div className="title">Domaine d'activité: {contact.domain}</div>
                  <div className="common-relations">{contact.location}</div>
                  <Link to="/messenger">
                    <button className="connect-btn">
                      <i className="fa fa-paper-plane"></i> Message
                    </button>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {isPopupOpen && (
        <div id="popup" className="popup">
          <div className="popup-content">
            <div className="sad-emoji">😥</div>
            <p>Vous ne disposez pas d'unité pour effectuer cette action</p>
            <button className="close-btn" style={{ marginRight: '20px' }} onClick={closePopup}>
              <i className="fa fa-window-close"></i> Fermer
            </button>
            <button className="buy-credits-btn" onClick={buyCredits}>
              <i className="fa fa-shopping-cart"></i> Acheter des crédits
            </button>
          </div>
        </div>
      )}
    </main>
  );
};

export default Networking;
