import React, { useState } from 'react';
import '../styles/ImageGallery.css'; // Importation du CSS personnalisé

const ImageGallery = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <div className="image-gallery">
            <button className="gallery-control prev" onClick={handlePrevClick}>
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </button>

            <div className="gallery-images">
                {images.length > 0 && (
                    <img src={images[currentIndex]} alt={`Image ${currentIndex + 1}`} />
                )}
            </div>

            <button className="gallery-control next" onClick={handleNextClick}>
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </button>
        </div>
    );
};

export default ImageGallery;
